import "core-js/modules/es.number.constructor.js";
import ToolbarBase from '@/components/ToolbarBase.vue';
var colSizeValidator = function colSizeValidator(value) {
  var number = typeof value === 'string' ? parseInt(value) : value;
  return number < 12 && number > 1;
};
export default defineComponent({
  components: {
    ToolbarBase: ToolbarBase
  },
  props: {
    leftColSize: {
      type: [Number, String],
      default: 6,
      validator: colSizeValidator
    },
    rightColSize: {
      type: [Number, String],
      default: 6,
      validator: colSizeValidator
    },
    isWide: {
      type: Boolean,
      default: false
    }
  }
});